<template >
    <div id="ticket">
      <div class="content_ticket">
         <p class="clave"> {{data.clave}}</p>
         <div class="brdr"></div>
         <p class="name"> {{data.nombre}}</p>
         <p class="fecha"> {{data.fecha}}</p>


               <button class="oculto-impresion" @click="imprimir()">IMPRIMIR</button>
      </div>

    </div>
</template>
<script>
import {mapActions} from 'vuex';
export default {
   
   async created(){
      if(!this.$route.query.id){
         this.$router.push("/administrador").catch(err => {});
      }else{
       

        let payload = {
            id: this.$route.query.id,
            option:'servicios_b'
        }
        await this.getInfoByIdTkt(payload)
        if(!data.id) {
            this.$router.push("/administrador").catch(err => {});
        }
        
      }
       
    },
    computed:{
        data(){
             return this.$store.getters["ticket/data"]
        },
    },
    methods:{
        ...mapActions('ticket',["getInfoByIdTkt"]),
           imprimir: function  () {
            window.print();
         },
    }
}
</script>
<style scoped  >
    
   #ticket{
    width: 252px;
    display:grid;
    margin-top: 30px;
    margin-left: 29px;
    color: rgba(0, 0, 0, 1);
    font-family: monospace;
   }
   .content_ticket{
   width:   194px;
   }
   .content_ticket p , h3{
    margin: 0vw;
   }
   .group{
     width: 178px;
     margin: auto;
  
   }

   p.clave{
    font-size: 23px;
    font-weight: 800;
   }

   p.name{
  font-weight: 800;
    height: 37px;
    margin-top: 3px;
    line-height: 13px;
    text-transform: uppercase;
   }
 
 p.fecha{
  font-weight: 800;
 }

   .brdr{
    border-bottom:5px solid rgba(0, 0, 0, 1);
    height: 1px;
   }
  

 



.oculto-impresion{
  margin-top: 10px;
  background: rgba(54, 57, 143, 1);
  border: none;
  color: white;
  text-transform: uppercase;
  font-family:monospace;
  width: 100%;
  height: 3vw;
  font-size: 1vw;
  font-weight: 700;


}



    @media print {
    .oculto-impresion,
    .oculto-impresion * {
        display: none !important;
    }
   }
 
</style>